import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

import { Page, ListContent } from 'src/app/components';

const CGU = () => {
  const { t } = useTranslation('vulcain');
  return (
    <Page>
      <Page.Main className="container pt-24 space-y-12">
        <header>
          <h1 className="p-4 text-2xl font-bold text-center border-2 lg:text-3xl border-primary-light text-primary-light">
            {ReactHtmlParser(t('cgus.header'))}
          </h1>
          <p className="pt-6 text-center">{ReactHtmlParser(t('cgus.subheader'))}</p>
        </header>
        <ListContent
          content={t('cgus.contents', { returnObjects: true })}
          headerClassName="text-lg font-bold text-primary-light"
          pointClassName="pt-4 space-y-4"
          subListClassName="space-y-2 list-disc list-inside"
        />
      </Page.Main>
    </Page>
  );
};

export default CGU;
